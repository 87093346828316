@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&subset=cyrillic,cyrillic-ext,latin-ext');
@import url('./asset/font/stylesheet.css');

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-100.otf') format('opentype');
	font-weight: 100;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-100-Italic.otf') format('opentype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-300.otf') format('opentype');
	font-weight: 300;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-300-Italic.otf') format('opentype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-500.otf') format('opentype');
	font-weight: 500;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-500-Italic.otf') format('opentype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-700.otf') format('opentype');
	font-weight: 700;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-700-Italic.otf') format('opentype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-900.otf') format('opentype');
	font-weight: 900;
}

@font-face {
	font-family: 'museo';
	src: url('./asset/font/Museo-Cyrl-900-Italic.otf') format('opentype');
	font-weight: 900;
	font-style: italic;
}
/*
	Grid CUSTOM css:
*/
.container-fluid {
	padding-left: 0 !important;
	padding-right: 0 !important;
}


/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
	font-family: 'HelveticaNeueCyr'; /*'Roboto', sans-serif;*/
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a, button {
	font-family: 'museo', 'Tahoma';
	text-decoration: none;
}

a button {
	text-decoration: none;
}

a {
	display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.15;
	font-weight: 600;
}

p {
	line-height: 1.25;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

a, button, input, label, select, textarea, h1, h2, h3, h4, h5, h6 {
	font-family: 'HelveticaNeueCyr'; /*'Roboto', sans-serif;*/
}

.menu-item:hover {
    background-color: #8783cd;
}
.menu-item:hover>a {
    color: #FFF !important;
}
.react-datepicker__input-container input {
	background-image: url('./asset/datepicker_icon.png');
	background-repeat: no-repeat;
	background-position: 95% 50%;
	border: 1px solid #CCC;
	width: 106px;
	font-family: "HelveticaNeueCyr";
	font-size: 14px;
	padding: 10px 5px;
}

* {
	outline: none;
}

input::-ms-clear {
	display: none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}