.popup-app-container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 201;
    background: white;
    -webkit-backface-visibility: hidden;
}
.popup-app-wrapper {
    padding: 10px 5px;
}
.mobile-icon-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
}