@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&subset=cyrillic,cyrillic-ext,latin-ext);
@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Medium.22e00c05.eot);
	src: local('HelveticaNeueCyr-Medium'),
		url(/static/media/HelveticaNeueCyr-Medium.22e00c05.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Medium.38778f81.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Medium.e7b6c004.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Heavy.5d1fc5d4.eot);
	src: local('HelveticaNeueCyr-Heavy'),
		url(/static/media/HelveticaNeueCyr-Heavy.5d1fc5d4.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Heavy.72595b67.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Heavy.dc8b4172.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-HeavyItalic.82dce003.eot);
	src: local('HelveticaNeueCyr-HeavyItalic'),
		url(/static/media/HelveticaNeueCyr-HeavyItalic.82dce003.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-HeavyItalic.a6659233.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-HeavyItalic.26ed5b20.ttf) format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Black.71deb16e.eot);
	src: local('HelveticaNeueCyr-Black'),
		url(/static/media/HelveticaNeueCyr-Black.71deb16e.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Black.b6ffcbe6.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Black.6db2b67c.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-UltraLightItalic.b1b6c88c.eot);
	src: local('HelveticaNeueCyr-UltraLightItalic'),
		url(/static/media/HelveticaNeueCyr-UltraLightItalic.b1b6c88c.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-UltraLightItalic.1f13f1b0.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-UltraLightItalic.2806e197.ttf) format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-BlackItalic.3532a826.eot);
	src: local('HelveticaNeueCyr-BlackItalic'),
		url(/static/media/HelveticaNeueCyr-BlackItalic.3532a826.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-BlackItalic.1c6b0eba.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-BlackItalic.bff89ca7.ttf) format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-ThinItalic.0cf32de0.eot);
	src: local('HelveticaNeueCyr-ThinItalic'),
		url(/static/media/HelveticaNeueCyr-ThinItalic.0cf32de0.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-ThinItalic.d7d4ef83.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-ThinItalic.a1b6dad0.ttf) format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-MediumItalic.48ed01fc.eot);
	src: local('HelveticaNeueCyr-MediumItalic'),
		url(/static/media/HelveticaNeueCyr-MediumItalic.48ed01fc.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-MediumItalic.b15f1f53.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-MediumItalic.58420d0c.ttf) format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-LightItalic.fc293dec.eot);
	src: local('HelveticaNeueCyr-LightItalic'),
		url(/static/media/HelveticaNeueCyr-LightItalic.fc293dec.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-LightItalic.489508b8.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-LightItalic.3a3f2d7e.ttf) format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-UltraLight.3e843a2a.eot);
	src: local('HelveticaNeueCyr-UltraLight'),
		url(/static/media/HelveticaNeueCyr-UltraLight.3e843a2a.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-UltraLight.ae307076.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-UltraLight.680db96a.ttf) format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-BoldItalic.12e494bc.eot);
	src: local('HelveticaNeueCyr-BoldItalic'),
		url(/static/media/HelveticaNeueCyr-BoldItalic.12e494bc.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-BoldItalic.0d1f683d.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-BoldItalic.4e0574ae.ttf) format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Italic.a296fb29.eot);
	src: local('HelveticaNeueCyr-Italic'),
		url(/static/media/HelveticaNeueCyr-Italic.a296fb29.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Italic.9e40af78.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Italic.39bd8b00.ttf) format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Roman.c31f99ef.eot);
	src: local('HelveticaNeueCyr-Roman'),
		url(/static/media/HelveticaNeueCyr-Roman.c31f99ef.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Roman.cd7c5a73.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Roman.46051620.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Bold.35112223.eot);
	src: local('HelveticaNeueCyr-Bold'),
		url(/static/media/HelveticaNeueCyr-Bold.35112223.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Bold.9400dbd2.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Bold.969916e0.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Light.5b2ace6e.eot);
	src: local('HelveticaNeueCyr-Light'),
		url(/static/media/HelveticaNeueCyr-Light.5b2ace6e.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Light.1eb80cac.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Light.12b3d3f9.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url(/static/media/HelveticaNeueCyr-Thin.78ca4254.eot);
	src: local('HelveticaNeueCyr-Thin'),
		url(/static/media/HelveticaNeueCyr-Thin.78ca4254.eot?#iefix) format('embedded-opentype'),
		url(/static/media/HelveticaNeueCyr-Thin.182674cb.woff) format('woff'),
		url(/static/media/HelveticaNeueCyr-Thin.e6e3a0ae.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}


@font-face {
	font-family: 'Roboto';
	src: url(/static/media/Roboto-Medium.f9f0c871.eot);
	src: local('Roboto-Medium'),
		url(/static/media/Roboto-Medium.f9f0c871.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Roboto-Medium.b14ca2d2.woff) format('woff'),
		url(/static/media/Roboto-Medium.ef677e21.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url(/static/media/Roboto-Regular.30b57652.eot);
	src: local('Roboto-Regular'),
		url(/static/media/Roboto-Regular.30b57652.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Roboto-Regular.31012f9b.woff) format('woff'),
		url(/static/media/Roboto-Regular.25e40da9.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Light.d66eb8dd.eot);
	src: local('Montserrat-Light'),
		url(/static/media/Montserrat-Light.d66eb8dd.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Light.be142e6c.woff) format('woff'),
		url(/static/media/Montserrat-Light.c3468d41.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Medium.418e43db.eot);
	src: local('Montserrat-Medium'),
		url(/static/media/Montserrat-Medium.418e43db.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Medium.d42dad28.woff) format('woff'),
		url(/static/media/Montserrat-Medium.693c527a.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Regular.3220cdd4.eot);
	src: local('Montserrat-Regular'),
		url(/static/media/Montserrat-Regular.3220cdd4.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Regular.3db65dc4.woff) format('woff'),
		url(/static/media/Montserrat-Regular.317b418e.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-100.f8376c68.otf) format('opentype');
	font-weight: 100;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-100-Italic.9e803d17.otf) format('opentype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-300.ed1904be.otf) format('opentype');
	font-weight: 300;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-300-Italic.b7b2b913.otf) format('opentype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-500.5777285a.otf) format('opentype');
	font-weight: 500;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-500-Italic.8a37540a.otf) format('opentype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-700.7164418b.otf) format('opentype');
	font-weight: 700;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-700-Italic.e42cbc47.otf) format('opentype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-900.9fd9d103.otf) format('opentype');
	font-weight: 900;
}

@font-face {
	font-family: 'museo';
	src: url(/static/media/Museo-Cyrl-900-Italic.e25ddde1.otf) format('opentype');
	font-weight: 900;
	font-style: italic;
}
/*
	Grid CUSTOM css:
*/
.container-fluid {
	padding-left: 0 !important;
	padding-right: 0 !important;
}


/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
	font-family: 'HelveticaNeueCyr'; /*'Roboto', sans-serif;*/
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a, button {
	font-family: 'museo', 'Tahoma';
	text-decoration: none;
}

a button {
	text-decoration: none;
}

a {
	display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.15;
	font-weight: 600;
}

p {
	line-height: 1.25;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

a, button, input, label, select, textarea, h1, h2, h3, h4, h5, h6 {
	font-family: 'HelveticaNeueCyr'; /*'Roboto', sans-serif;*/
}

.menu-item:hover {
    background-color: #8783cd;
}
.menu-item:hover>a {
    color: #FFF !important;
}
.react-datepicker__input-container input {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAAA00lEQVQ4jaWUXQ6DMAyDbdTTAbdNej28hy1VycrPxveCWgXHsQKUJHxwdwDAuq4AAEkgiYy7gySWZWl3NLMmRBKhGwJdH+S6vqb0DrKjI6JunmeQRK31LRRcjZTrAkkoJFuH0Yh3KZKGHc4c5Rp3xxSX2VF+KZNrpqNid28jx/h9BPlcRupXDPMzM/3Ltm2SJHfX9C19n34hm5C7o9aK/nyWST7vHP26O+GI5LOMAjN7llHPLqNfMjkUeswoo9iPq/2Jp5mpiIDVY8sZfT7W3R+CwAtIilVJ1xO7KgAAAABJRU5ErkJggg==);
	background-repeat: no-repeat;
	background-position: 95% 50%;
	border: 1px solid #CCC;
	width: 106px;
	font-family: "HelveticaNeueCyr";
	font-size: 14px;
	padding: 10px 5px;
}

* {
	outline: none;
}

input::-ms-clear {
	display: none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}
.buttonCreate {
  width: 26px;
  height: 26px;
  background-color: #75d1ad;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-size: 34px;
  /* font-weight: bold; */
}

.text_center_check{
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    color: rgb(135, 131, 204);
    font-size: 20px;
}
.footer {
    display: flex;
    justify-content: center;
    background-color: #534972;
    height: 52px;
}

.footer__container {
    min-width: 1152px;
    padding: 0 40px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__span {
    font-size: 16px;
    font-family: "HelveticaNeueCyr";
    color: rgb(255, 255, 255);
}

.social {
    position: relative;
    display: inline-block;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #6c628b;
    color: #fff;
}
.social__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    margin: 0 auto;
}
.ReactModalPortal > div {
    opacity: 0;
  }
  
  .ReactModalPortal .ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease-in-out;
    z-index: 1500;
  }
  
  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .modal {
    width: 300px;
    height: 140px;
    padding: 25px;
    border: 1px solid #e9eef2;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
  }

  .modal_big {
    width: 360px;
    height: 170px;
    padding: 25px;
    border: 1px solid #e9eef2;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
  }
  
  .modal__option {
    padding-bottom: 20px;
    color: #313131;
    font-size: 16px;
    font-weight: 400;
  }

  .modal__button:not(:last-child) {
    margin-right: 80px;
  }
.popup-app-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 201;
    background: white;
    -webkit-backface-visibility: hidden;
}
.popup-app-wrapper {
    padding: 10px 5px;
}
.mobile-icon-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
}
.custom-button {
    width: 300;
    font-size: 12px;
    color: #fff;
    height: 30px;
    box-sizing: border-box;
    text-transform: uppercase;
    background-color: #daa1c1;
    border: none;
    padding: 0 12px;
    cursor: pointer;
}

.custom-button__primary {
    background-color: #8783cc;
}

.custom-button__disabled {
  pointer-events: none;
  background-color: #ccc;
  cursor: default;
}

