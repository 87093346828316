.custom-button {
    width: 300;
    font-size: 12px;
    color: #fff;
    height: 30px;
    box-sizing: border-box;
    text-transform: uppercase;
    background-color: #daa1c1;
    border: none;
    padding: 0 12px;
    cursor: pointer;
}

.custom-button__primary {
    background-color: #8783cc;
}

.custom-button__disabled {
  pointer-events: none;
  background-color: #ccc;
  cursor: default;
}
