.ReactModalPortal > div {
    opacity: 0;
  }
  
  .ReactModalPortal .ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease-in-out;
    z-index: 1500;
  }
  
  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .modal {
    width: 300px;
    height: 140px;
    padding: 25px;
    border: 1px solid #e9eef2;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
  }

  .modal_big {
    width: 360px;
    height: 170px;
    padding: 25px;
    border: 1px solid #e9eef2;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
  }
  
  .modal__option {
    padding-bottom: 20px;
    color: #313131;
    font-size: 16px;
    font-weight: 400;
  }

  .modal__button:not(:last-child) {
    margin-right: 80px;
  }