.buttonCreate {
  width: 26px;
  height: 26px;
  background-color: #75d1ad;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-size: 34px;
  /* font-weight: bold; */
}
