.footer {
    display: flex;
    justify-content: center;
    background-color: #534972;
    height: 52px;
}

.footer__container {
    min-width: 1152px;
    padding: 0 40px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__span {
    font-size: 16px;
    font-family: "HelveticaNeueCyr";
    color: rgb(255, 255, 255);
}

.social {
    position: relative;
    display: inline-block;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #6c628b;
    color: #fff;
}
.social__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    margin: 0 auto;
}